body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-with-margin {
  margin-top: 50px; 
  margin-bottom: 50px;
}

.more:hover{
  background-color: #1F334B!important;
}

.theme-color {
  color: #0c3348;
}


/* Add this CSS to your styles.css file or your preferred stylesheet */
.my-navbar .nav-link {
  color: '#1F334B';
  transition: color 0.3s, background-color 0.3s; /* Add transitions */
}

.my-navbar .nav-link:hover {
  opacity: 0.4;
}

a.active {
  font-weight: bold;
  color: #000; /* Change color for the active link */
}

.nav-tabs{
  --bs-nav-tabs-border-width: none
}
.nav-tabs .active{
  font-weight: 'bold'!important;
  border-bottom: 4px solid #1F334B!important;
}
.navbar{
  border-bottom: none!important;
}
.custom-progressbar {
  height: 100px; 
  width: 100px; 
}
.navbar-toggler{
  background-color: var(--bs-gray-100)!important;
  opacity: 0.8;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #1F334B;
}

.contact-us-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-info,
.communication-form {
  flex-basis: 45%;
  padding: 20px;
  border-radius: 5px;
  margin-top: 50px;
}

.map-container {
  margin-top: 30px;
  margin-bottom: 50px; 

  color: #0c3348;
}

#expo-container {
  position: relative;
  overflow: hidden;
  margin: 100px 0;
  background-color: #f7f7f7;
}
#expo {
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap to the next row if needed */
  justify-content: center;
  align-items: center; /* Vertically center items */
  margin-left: -15px;
  margin-right: -15px;
  padding: 100px 15px; /* Adjust the horizontal padding to maintain spacing */
  padding-bottom: 80px;
  transition: all 0.4s ease;

}

.circle-icon {
  background: radial-gradient(circle, #1F334B, #000000);
  width: 150px; /* Equal width and height to create a circle */
  height: 150px; /* Equal width and height to create a circle */
  border-radius: 50%; /* Set border-radius to 50% for a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px; /* Adjust the font size as needed */
  color: #fff; /* Text color */
}


.service-box {
  background-color: #e0e7f1; /* Default background color */
  transition: background-color 0.3s; /* Smooth transition on hover */
  border-radius: 15px;
}

.service:hover {
  background-color: #b2c3db; /* Change background color on hover */
}

.circular-image-ad{
  width: 150px; /* Adjust the width as needed */
  height: 150px; /* Adjust the height as needed (same as width for a perfect circle) */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image fills the circular container */
  padding-left: 10px;
  margin: 20px;
  overflow: visible;
  background-color: white;
}

.circular-image-large {

  width: 150px; /* Adjust the width as needed */
  height: 150px; /* Adjust the height as needed (same as width for a perfect circle) */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image fills the circular container */
  padding: 30px;
  margin: 20px;
  overflow: visible;
  background-color: white;
}

.circular-image {
  width: 150px; /* Adjust the width as needed */
  height: 150px; /* Adjust the height as needed (same as width for a perfect circle) */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image fills the circular container */
  margin: 20px;
  overflow: visible;
  background-color: white;
}
.description-container {
  max-height: 200px; /* Adjust the maximum height as needed */
  overflow: hidden;
}



.service-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Adjust the number of lines to control the height */
  -webkit-box-orient: vertical;
  line-height: 1.2; /* Adjust the line height to match your design */
  font-size: 16px; /* Adjust the font size to match your design */
}


.circular-progress-bar {
  transition: stroke-dashoffset 0.5s ease-in-out;
}



/* Add this CSS to your stylesheets */

.custom-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: 650px; /* Set the maximum width as desired */
  min-height: 50vh; /* Set the minimum height as desired */
}



.modern-arrow-button-right {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  position: relative;
}

.modern-arrow-button-right:disabled {
  opacity: 0.2; /* Reduce opacity for disabled state */
  cursor: not-allowed; /* Change cursor for disabled state */
}


.modern-arrow-button-right::before {
  content: '>'; /* Greater-than symbol */
  font-size: 30px; /* Adjust the font size as needed */
  font-weight: 200;
  color: #333; /* Arrow color */
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  
.modern-arrow-button-left {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  position: relative;
}


.modern-arrow-button-left:disabled {
  opacity: 0.2; /* Reduce opacity for disabled state */
  cursor: not-allowed; /* Change cursor for disabled state */
}

.modern-arrow-button-left::before {
  content: '<'; /* Greater-than symbol */
  font-size: 30px; /* Adjust the font size as needed */
  color: #333; /* Arrow color */
  font-weight: 200;

  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.hidden {
  display: none;
}

.tab-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tab {
  flex: 1;
  border: 1px solid #DEE2E6;
  text-align: center;
}

.nav-fill {
  display: inline-flex;
  align-items: center;


}
